.root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    background-color: var(--tertiary-050);
    background-size: auto 100%;
    background-image: url(./parts/img/tspanampa-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
}

.wrapper {
    width: 860px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--black-90);
    margin-top: 90px;
    margin-bottom: 90px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.root > footer {
    margin-top: auto;
}

@media (min-width: 1024px) {
    .root {
        background-size: cover;
        min-height: 100vh;
    }
    .wrapper {
        width: 860px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .root {
        width: 100%;
        min-height: 100%;
    }
    .wrapper {
        width: 100%;
    }
}
