.root {
    width: 100%;
    height: 30px;
    border: solid 1px var(--black-150);
    background-color: transparent;
    outline: none;
    padding: 8px 14px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    border-radius: 3px;
}

.root::placeholder {
    color: rgba(85, 85, 85, 0.623);
    font-weight: 300;
}

.root:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.root:disabled {
    cursor: not-allowed;
    background-color: #eee;
    border: 1px solid #bebebe;
}

.rootError {
    border-color: #df1642;
}

.rootError:focus {
    border-color: #df1642;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(223, 22, 66, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(223, 22, 66, 0.6);
}

.error {
    margin-top: 3px;
    color: #df1642;
    margin-bottom: 5px;
}
